module.exports = [{
      plugin: require('/Users/dervis/Code/moonvision_website/node_modules/gatsby-plugin-crisp-chat/gatsby-browser.js'),
      options: {"plugins":[],"websiteId":"af8262b6-9ce9-4fc6-b506-e3fe6616c995","enableDuringDevelop":true,"defer":true,"enableImprovedAccessibility":false},
    },{
      plugin: require('/Users/dervis/Code/moonvision_website/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"MoonVision - Visual Inspection","short_name":"MoonVision","description":"Shorten your quality control cycle time with MoonVision's most advanced visual recognition.","lang":"en","display":"minimal-ui","icon":"src/images/icon.png","start_url":"/","background_color":"#ffffff","theme_color":"#06a2c7","localize":[{"start_url":"/de/","lang":"de","name":"MoonVision - Visuelle Inspektion","short_name":"MoonVision","description":"Kürzen Sie die Durchlaufzeit ihrer Qualitätskontrolle durch die fortschrittlichste visuelle Erkennung von MoonVision.\n            "}]},
    },{
      plugin: require('/Users/dervis/Code/moonvision_website/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TJLDZHH","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('/Users/dervis/Code/moonvision_website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
