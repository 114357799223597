// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-de-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/about/index.de.js" /* webpackChunkName: "component---src-pages-about-index-de-js" */),
  "component---src-pages-about-index-en-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/about/index.en.js" /* webpackChunkName: "component---src-pages-about-index-en-js" */),
  "component---src-pages-career-frontend-dev-role-index-de-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/career/frontend-dev-role/index.de.js" /* webpackChunkName: "component---src-pages-career-frontend-dev-role-index-de-js" */),
  "component---src-pages-career-frontend-dev-role-index-en-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/career/frontend-dev-role/index.en.js" /* webpackChunkName: "component---src-pages-career-frontend-dev-role-index-en-js" */),
  "component---src-pages-career-index-de-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/career/index.de.js" /* webpackChunkName: "component---src-pages-career-index-de-js" */),
  "component---src-pages-career-index-en-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/career/index.en.js" /* webpackChunkName: "component---src-pages-career-index-en-js" */),
  "component---src-pages-consulting-index-de-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/consulting/index.de.js" /* webpackChunkName: "component---src-pages-consulting-index-de-js" */),
  "component---src-pages-consulting-index-en-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/consulting/index.en.js" /* webpackChunkName: "component---src-pages-consulting-index-en-js" */),
  "component---src-pages-contact-de-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/contact.de.js" /* webpackChunkName: "component---src-pages-contact-de-js" */),
  "component---src-pages-contact-en-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-data-privacy-de-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/data-privacy.de.js" /* webpackChunkName: "component---src-pages-data-privacy-de-js" */),
  "component---src-pages-data-privacy-en-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/data-privacy.en.js" /* webpackChunkName: "component---src-pages-data-privacy-en-js" */),
  "component---src-pages-imprint-de-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/imprint.de.js" /* webpackChunkName: "component---src-pages-imprint-de-js" */),
  "component---src-pages-imprint-en-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/imprint.en.js" /* webpackChunkName: "component---src-pages-imprint-en-js" */),
  "component---src-pages-index-de-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partnership-certified-partner-index-de-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/partnership/certified-partner/index.de.js" /* webpackChunkName: "component---src-pages-partnership-certified-partner-index-de-js" */),
  "component---src-pages-partnership-certified-partner-index-en-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/partnership/certified-partner/index.en.js" /* webpackChunkName: "component---src-pages-partnership-certified-partner-index-en-js" */),
  "component---src-pages-partnership-index-de-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/partnership/index.de.js" /* webpackChunkName: "component---src-pages-partnership-index-de-js" */),
  "component---src-pages-partnership-index-en-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/partnership/index.en.js" /* webpackChunkName: "component---src-pages-partnership-index-en-js" */),
  "component---src-pages-products-assemblycontrol-index-de-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/products/assemblycontrol/index.de.js" /* webpackChunkName: "component---src-pages-products-assemblycontrol-index-de-js" */),
  "component---src-pages-products-assemblycontrol-index-en-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/products/assemblycontrol/index.en.js" /* webpackChunkName: "component---src-pages-products-assemblycontrol-index-en-js" */),
  "component---src-pages-products-index-de-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/products/index.de.js" /* webpackChunkName: "component---src-pages-products-index-de-js" */),
  "component---src-pages-products-index-en-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/products/index.en.js" /* webpackChunkName: "component---src-pages-products-index-en-js" */),
  "component---src-pages-products-logisticscontrol-index-de-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/products/logisticscontrol/index.de.js" /* webpackChunkName: "component---src-pages-products-logisticscontrol-index-de-js" */),
  "component---src-pages-products-logisticscontrol-index-en-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/products/logisticscontrol/index.en.js" /* webpackChunkName: "component---src-pages-products-logisticscontrol-index-en-js" */),
  "component---src-pages-products-metalscanner-index-de-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/products/metalscanner/index.de.js" /* webpackChunkName: "component---src-pages-products-metalscanner-index-de-js" */),
  "component---src-pages-products-metalscanner-index-en-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/products/metalscanner/index.en.js" /* webpackChunkName: "component---src-pages-products-metalscanner-index-en-js" */),
  "component---src-pages-products-paintscanner-index-de-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/products/paintscanner/index.de.js" /* webpackChunkName: "component---src-pages-products-paintscanner-index-de-js" */),
  "component---src-pages-products-paintscanner-index-en-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/products/paintscanner/index.en.js" /* webpackChunkName: "component---src-pages-products-paintscanner-index-en-js" */),
  "component---src-pages-products-surfacescanner-index-de-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/products/surfacescanner/index.de.js" /* webpackChunkName: "component---src-pages-products-surfacescanner-index-de-js" */),
  "component---src-pages-products-surfacescanner-index-en-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/products/surfacescanner/index.en.js" /* webpackChunkName: "component---src-pages-products-surfacescanner-index-en-js" */),
  "component---src-pages-products-toolbox-index-de-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/products/toolbox/index.de.js" /* webpackChunkName: "component---src-pages-products-toolbox-index-de-js" */),
  "component---src-pages-products-toolbox-index-en-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/products/toolbox/index.en.js" /* webpackChunkName: "component---src-pages-products-toolbox-index-en-js" */),
  "component---src-pages-products-weldscanner-index-de-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/products/weldscanner/index.de.js" /* webpackChunkName: "component---src-pages-products-weldscanner-index-de-js" */),
  "component---src-pages-products-weldscanner-index-en-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/products/weldscanner/index.en.js" /* webpackChunkName: "component---src-pages-products-weldscanner-index-en-js" */),
  "component---src-pages-products-woodscanner-index-de-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/products/woodscanner/index.de.js" /* webpackChunkName: "component---src-pages-products-woodscanner-index-de-js" */),
  "component---src-pages-products-woodscanner-index-en-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/products/woodscanner/index.en.js" /* webpackChunkName: "component---src-pages-products-woodscanner-index-en-js" */),
  "component---src-pages-technology-index-de-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/technology/index.de.js" /* webpackChunkName: "component---src-pages-technology-index-de-js" */),
  "component---src-pages-technology-index-en-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/technology/index.en.js" /* webpackChunkName: "component---src-pages-technology-index-en-js" */),
  "component---src-pages-terms-conditions-de-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/terms-conditions.de.js" /* webpackChunkName: "component---src-pages-terms-conditions-de-js" */),
  "component---src-pages-terms-conditions-en-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/terms-conditions.en.js" /* webpackChunkName: "component---src-pages-terms-conditions-en-js" */),
  "component---src-pages-use-cases-index-de-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/use-cases/index.de.js" /* webpackChunkName: "component---src-pages-use-cases-index-de-js" */),
  "component---src-pages-use-cases-index-en-js": () => import("/Users/dervis/Code/moonvision_website/src/pages/use-cases/index.en.js" /* webpackChunkName: "component---src-pages-use-cases-index-en-js" */)
}

